@font-face {
  font-family: "Pixel";
  src: url("../../../public/fonts/pixel.ttf") format("truetype");
}

@font-face {
  font-family: "Gastromond";
  src: url("../../../public/fonts/gastromond.otf") format("truetype");
}

@font-face {
  font-family: "Pixel-Bold";
  src: url("../../../public/fonts/pixel-bold.ttf") format("truetype");
}

.about-me-parent {
  overflow-y: scroll;
  padding-top: 60px;
  padding-bottom: 30px;
}

.about-me {
  font-family: "Gastromond";
  font-size: 40px;
}

.about-me-content {
  font-family: "Pixel";
}

.bold {
  color: rgb(239, 135, 51);
  font-weight: bold;
}

.bold a {
  font-weight: bold;
  color: rgb(239, 135, 51);
  cursor: url("../../../public/cursor/default_link.cur"), pointer;
}

.intro {
  width: 96.4%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-top: -20px;
}

.intro-content {
  margin-top: -15px;
}

.backstory {
  margin-top: 20px;
  width: 96.4%;
  /* display: flex;
  flex-wrap: wrap; */
  position: relative;
}

.self {
  float: left;
  margin-left: 5px;
  margin-left: 10px;
  margin-bottom: 0px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.self img {
  width: 230px;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.self p {
  font-weight: bold;
  margin-top: 2px;
  font-size: 12px;
  margin-top: 7px;
}

.robotics-picture {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.robotics-picture img {
  width: 250px;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.robotics-picture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -4%;
  margin-right: 10px;
}

.robotics-picture-container p {
  font-weight: bold;
  margin-top: 2px;
  font-size: 12px;
  margin-top: 7px;
}

.astronaut-picture {
  float: right;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.astronaut-picture img {
  width: 190px;
  height: auto;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.astronaut-picture p {
  font-weight: bold;
  margin-top: 7px;
  font-size: 12px;
  margin-bottom: 0px;
}

.bottle-rocket-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.bottle-rocket-container p {
  font-weight: bold;
  margin-top: 2px;
  font-size: 12px;
  width: 200px;
  text-align: center;
  margin-top: 7px;
}

.hobbies-container {
  margin-top: 30px;
  width: 96.4%;
}

.hobbies-container .subtitle {
  font-family: "Pixel-Bold";
  font-size: 12px;
  font-weight: 600;
}

.passion-projects {
  margin-top: 20px;
}

.passion-projects span {
  font-size: 17px;
  font-weight: bold;
}

.sports {
  margin-top: 20px;
}

.sports span {
  font-size: 17px;
  font-weight: bold;
}

.movies {
  margin-top: 20px;
  width: 96.4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.movies span {
  font-size: 17px;
  font-weight: bold;
}

.superhero-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.superhero-image img {
  width: 70%;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.superhero-image p {
  font-weight: bold;
  margin-top: 2px;
  font-size: 12px;
  width: 200px;
  text-align: center;
  margin-top: 7px;
}

.skating-picture {
  float: left;
  margin-left: 5px;
  margin-right: 12px;
  margin-top: 7px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skating-picture img {
  width: 150px;
  height: auto;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.skating-picture p {
  font-weight: bold;
  margin-top: 7px;
  font-size: 12px;
  margin-bottom: 0px;
}

.sports-pictures {
  display: flex;
  width: 96.4%;
  margin-top: 10px;
  justify-content: center;
}

.skiing-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skiing-picture img {
  width: auto;
  height: 255px;
  margin-left: 7px;
  margin-right: 7px;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.skiing-picture p {
  font-weight: bold;
  margin-top: 7px;
  font-size: 12px;
  margin-bottom: 0px;
}

.snowboarding-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.snowboarding-picture img {
  width: auto;
  height: 255px;
  margin-left: 7px;
  margin-right: 7px;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.snowboarding-picture p {
  font-weight: bold;
  margin-top: 7px;
  font-size: 12px;
  margin-bottom: 0px;
}

.bouldering-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bouldering-picture img {
  width: auto;
  height: 255px;
  margin-left: 7px;
  margin-right: 7px;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.bouldering-picture p {
  font-weight: bold;
  margin-top: 7px;
  font-size: 12px;
  margin-bottom: 0px;
  text-align: center;
  width: 200px;
}

.passion-projects-content .projects-hyperlink {
  font-size: 15px;
  text-decoration: underline;
  cursor: url("../../../public/cursor/default_link.cur"), pointer;
  color: rgb(239, 135, 51);
}

.movies-content .projects-hyperlink {
  font-size: 15px;
  text-decoration: underline;
  cursor: url("../../../public/cursor/default_link.cur"), pointer;
  color: rgb(239, 135, 51);
}

.intro-content .projects-hyperlink {
  font-size: 15px;
  text-decoration: underline;
  cursor: url("../../../public/cursor/default_link.cur"), pointer;
  color: rgb(239, 135, 51);
  font-weight: bold;
}

@media (max-width: 768px) {
  .intro {
    width: 83%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin-top: 20px;
  }

  .self {
    float: left;
    margin-left: 5px;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .self img {
    width: 130px;
    box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
      0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
      0 0 0 5px rgb(200, 203, 207);
  }

  .self p {
    font-weight: bold;
    margin-top: 2px;
    font-size: 12px;
    margin-top: 7px;
  }

  .astronaut-picture img {
    width: 130px;
    height: auto;
    box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
      0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
      0 0 0 5px rgb(200, 203, 207);
  }

  .backstory {
    margin-top: 20px;
    width: 83%;
    position: relative;
  }

  .hobbies-container {
    margin-top: 30px;
    width: 83%;
  }

  .movies {
    margin-top: 20px;
    width: 83%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .sports-pictures {
    display: flex;
    width: 83%;
    margin-top: 10px;
    justify-content: center;
  }

  .robotics-picture {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-left: -40px;
  }

  .robotics-picture img {
    width: 200px;
    box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
      0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
      0 0 0 5px rgb(200, 203, 207);
  }

  .robotics-picture-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .robotics-picture-container p {
    font-weight: bold;
    margin-top: 2px;
    font-size: 12px;
    margin-top: 7px;
  }

  .bottle-rocket-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -20px;
  }

  .bottle-rocket-container p {
    font-weight: bold;
    margin-top: 2px;
    font-size: 12px;
    width: 200px;
    text-align: center;
    margin-top: 7px;
  }

  .sports-pictures {
    display: flex;
    flex-direction: column;
    width: 83%;
    margin-top: 10px;
    justify-content: center;
  }

  .skiing-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .skiing-picture img {
    width: auto;
    height: 215px;
    margin-left: 7px;
    margin-right: 7px;
    box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
      0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
      0 0 0 5px rgb(200, 203, 207);
  }

  .snowboarding-picture {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .snowboarding-picture img {
    width: auto;
    height: 215px;
    margin-left: 7px;
    margin-right: 7px;
    box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
      0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
      0 0 0 5px rgb(200, 203, 207);
  }

  .bouldering-picture {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bouldering-picture img {
    width: auto;
    height: 215px;
    margin-left: 7px;
    margin-right: 7px;
    box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
      0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
      0 0 0 5px rgb(200, 203, 207);
  }
}
