@font-face {
  font-family: "Pixel";
  src: url("../../../public/fonts/pixel.ttf") format("truetype");
}

@font-face {
  font-family: "Gastromond";
  src: url("../../../public/fonts/gastromond.otf") format("truetype");
}

@font-face {
  font-family: "Pixel-Bold";
  src: url("../../../public/fonts/pixel-bold.ttf") format("truetype");
}

.projects-parent {
  overflow-y: scroll;
  padding-top: 60px;
  padding-bottom: 30px;
}

.projects {
  font-family: "Gastromond";
  font-size: 40px;
}

.projects-content {
  font-family: "Pixel";
  image-rendering: pixelated;
}

.projects-intro {
  width: 96.4%;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.matialee {
  margin-top: 50px;
  width: 96.4%;
}

.matialee .subtitle {
  font-family: "Pixel-Bold";
  font-size: 16px;
  font-weight: 600;
}

.matialee-explanation {
  margin-top: 10px;
}

.matialee-demo {
  display: flex;
  justify-content: left;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.matialee video {
  width: 99%;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.matialee-techstack {
  margin-top: 10px;
}

.matialee-techstack .techstack-subtitle {
  color: rgb(239, 135, 51);
  font-weight: bold;
  font-size: 18px;
  margin-bottom: -10px;
}

.matialee-techstack ul {
  margin-top: 10px;
}

.matialee-techstack li {
  font-weight: bold;
}

.letterstats {
  margin-top: 60px;
  width: 96.4%;
}

.letterstats .subtitle {
  font-family: "Pixel-Bold";
  font-size: 16px;
  font-weight: 600;
}

.letterstats-explanation {
  margin-top: 10px;
}

.letterstats-demo {
  display: flex;
  justify-content: left;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.letterstats video {
  width: 99%;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.letterstats-explanation a {
  font-weight: bold;
}

.features-subtitle {
  color: rgb(239, 135, 51);
  font-weight: bold;
  font-size: 18px;
  margin-bottom: -10px;
}

.features ul {
  margin-top: 10px;
}

.features li {
  font-weight: bold;
}

.frontend-techstack {
  font-weight: bold;
  margin-bottom: -10px;
  font-size: 17px;
}

.backend-techstack {
  font-weight: bold;
  margin-bottom: -10px;
  margin-top: 10px;
  font-size: 17px;
}

.credentials {
  width: 96.4%;
  margin-top: 15px;
}

.credentials-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.credentials img {
  width: 175px;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.credentials p {
  text-align: center;
  margin-top: 7px;
  text-decoration: underline;
}

.outfitly-techstack {
  margin-top: -10px;
}

.outfitly-techstack .techstack-subtitle {
  color: rgb(239, 135, 51);
  font-weight: bold;
  font-size: 18px;
  margin-bottom: -10px;
}

.outfitly-techstack ul {
  margin-top: 10px;
}

.outfitly-techstack li {
  font-weight: bold;
}

.cineberg-techstack {
  margin-top: 10px;
}

.cineberg-techstack .techstack-subtitle {
  color: rgb(239, 135, 51);
  font-weight: bold;
  font-size: 18px;
  margin-bottom: -10px;
}

.cineberg-techstack ul {
  margin-top: 10px;
}

.cineberg-techstack li {
  font-weight: bold;
}

.patty-wagon-pics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.patty-wagon-pics img {
  width: 500px;
  height: auto;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
}

.patty-wagon-pics p {
  font-weight: bold;
  margin-top: 7px;
  font-size: 12px;
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  .projects-intro {
    width: 83%;
    margin-top: 5px;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .matialee {
    margin-top: 50px;
    width: 83%;
  }

  .letterstats {
    margin-top: 60px;
    width: 83%;
  }
}
