.fox {
  overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 30px;
}

.fox-title {
  font-family: "Pixel-Bold";
  width: 96.4%;
}

.fox-analysis {
  /* font-family: "Pixel"; */
  width: 96.4%;
}

.fox-analysis .scene {
  margin-left: 5px;
  margin-bottom: 15px;
}

.fox-analysis video {
  width: 99%;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
  cursor: url("../../../../../public/cursor/default_link.cur"), pointer;
}

@media (max-width: 768px) {
  .fox-title {
    font-family: "Pixel-Bold";
    width: 83%;
  }

  .fox-analysis {
    /* font-family: "Pixel"; */
    width: 83%;
  }
}
