.movie-blog-title {
  font-family: "Gastromond";
  font-size: 40px;
}

.movie-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
  margin-left: 5px;
  width: 96.4%;
}

.movie {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.movie img {
  width: 140px;
  height: 207px;
  box-shadow: 0 0 0 1px rgb(133, 132, 133), 0 0 0 2px rgb(203, 201, 197),
    0 0 0 3px rgb(219, 217, 213), 0 0 0 4px rgb(244, 244, 243),
    0 0 0 5px rgb(200, 203, 207);
  cursor: url("../../../public/cursor/default_link.cur"), pointer;
}

.movie img:hover {
  width: 140px;
  box-shadow: 0 0 0 1px rgb(103, 102, 103), 0 0 0 2px rgb(163, 161, 157),
    0 0 0 3px rgb(169, 167, 163), 0 0 0 4px rgb(194, 194, 193),
    0 0 0 5px rgb(150, 153, 157);
  cursor: url("../../../public/cursor/default_link.cur"), pointer;
}

.movie p {
  font-family: "Pixel";
  text-align: center;
  width: 130px;
  margin-top: 8px;
  font-size: 13px;
}

@media (max-width: 768px) {
  .movie-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: -5px;
    width: 83%;
  }

  .movie-blog {
    overflow-y: scroll;
    padding-top: 40px;
    padding-bottom: 30px;
  }
}
